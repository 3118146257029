<template>
	<CustomerForm :is-update="isUpdate" @submit="updateCustomer" @cancel="cancel" />
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex"
import { PARAMS } from "../../../shared/plugins/constants"
import { GET_TYPES_ORDER_PRICE } from "../../typeOrder/store/action-types"
import { RESET_STATE } from "../../typeOrder/store/mutation-types"
import { GET_CUSTOMER_DETAIL, UPDATE_CUSTOMER } from "../store/action-types"
import { RESET_CUSTOMER_FILTERS } from "../store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("customers")
const {
	mapActions: typeOrderActions,
	mapState: typeOrderState,
	mapMutations: typeOrderMutations,
} = createNamespacedHelpers("typeOrder")
export default {
	name: "UpdateCustomer",
	components: {
		CustomerForm: () => import("../components/CustomerForm"),
	},
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			offset: PARAMS.OFFSET,
		}
	},
	computed: {
		...typeOrderState(["recentFilters"]),
		...mapState(["pageChanged"]),
	},
	watch: {
		pageChanged(val) {
			if (val) {
				this.offset = val.offset
				this.getTypes()
			}
		},
	},
	created() {
		this.getTypes()
		this.getCustomerDetail()
	},
	beforeDestroy() {
		this.RESET_CUSTOMER_FILTERS()
		this.RESET_STATE()
		this.$store.commit("set", ["pageChanged", null])
	},
	methods: {
		...mapActions({ GET_CUSTOMER_DETAIL, UPDATE_CUSTOMER }),
		...mapMutations({ RESET_CUSTOMER_FILTERS }),
		...typeOrderActions({ GET_TYPES_ORDER_PRICE }),
		...typeOrderMutations({ RESET_STATE }),
		async getCustomerDetail() {
			const params = {
				id: this.id,
			}
			await this.GET_CUSTOMER_DETAIL(params)
		},
		async updateCustomer(params) {
			await this.UPDATE_CUSTOMER(params)
		},
		cancel() {
			this.$router.push({ name: "CustomerManagement" })
		},
		async getTypes() {
			const params = {
				data: {
					...this.recentFilters,
					customerId: this.id,
					offset: this.offset,
				},
				notLoading: true,
			}
			await this.GET_TYPES_ORDER_PRICE(params)
		},
	},
}
</script>

<style></style>
